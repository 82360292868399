.services {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    box-sizing: border-box;
}

.service {
    width: 48%;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 2vw;
}

.service .service-image {
    position: relative;
    height: 22vw;
    width: 100%;
    overflow: hidden;
}

.service .service-logo img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: top 0.2s ease-in-out;
    display: block;
}

.service:hover .service-logo img {
    top: 35%;
}

.service .service-type {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.9vw;
    font-weight: bold;
    word-spacing: 0.2vw;
    line-height: 1.4vw;
    padding: 3vw 0;
    box-sizing: border-box;
}

.services-contact-button {
    margin: 5vw 0;
    position: relative;
    border: 1px solid transparent;
}

.services-contact-button a {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media only screen and (max-width: 480px) {
    .service { 
        width: 100% !important;
        margin-bottom: 30px;
    }

    .service .service-image img {
        height: 100%;
        width: auto;
    }

    .service .service-image {
        height: 90vw;
    }

    .service .service-type {
        font-size: 0.8em;
        line-height: 1.4em;
        width: 80%;
        margin: 0 auto;
        padding: 30px 0;
    }

    .services-contact-button a {
        letter-spacing: 1px;
        word-spacing: normal !important;
    }

}