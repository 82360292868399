.content-header {
    font-size: 2vw;
    line-height: 1.5vw;
    font-weight: 100;
    letter-spacing: 0.1vw;
}

.content-header.mc-header {
    text-align: center;
    position: relative;
    margin-bottom: 4vw;
    text-transform: uppercase;
    font-size: 1.5vw;
}

.content-header.mc-header::before {
    position: absolute;
    content: ".";
    bottom: -2vw;
    width: 25%;
    font-size: 3vw;
    color: #666;
    left: 50%;
    transform: translateX(-50%);
}

.std-p {
    line-height: 2vw;
    font-size: 1.2vw;
}

.container.condensed {
    width: 60% !important;
}

@media only screen and (max-width: 480px) {

    .content-header {
        font-size: 2em !important;
        line-height: 1.5em !important;
        letter-spacing: 1px !important;
    }

    .content-header.mc-header {
        margin-bottom: 40px !important;
        font-size: 1.2em !important;
    }
    
    .content-header.mc-header::before {
        bottom: -20px !important;
        font-size: 2em !important;
    }

    .std-p {
        font-size: 1em !important;
        line-height: 1.5em !important;
    }

    .container.condensed {
        width: 85% !important;
    }
}