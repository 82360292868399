

.app {
    position: relative;
}

#main-header {
    height: 100vh;
    max-width: 100%;
    position: relative;
    background: url('/images/main-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 70%;
}

main {
    min-height: calc(100vh - 4.5vw);
    background: #f5f5f5;
}

.container {
    margin: 0 auto;
    width: 80%;   
}

.container.padding {
    padding-top: 7vw;
    padding-bottom: 2.5vw;
}

header {
    position: absolute;
    width: 100%;
    height: 4.5vw;
    z-index: 1;
    background: #fff;
}

header .container {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

#site-logo {
    height: 4vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.header-menu {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.header-menu a {
    font-size: 1vw;
    margin: 0 1.5vw;
    text-decoration: none;
    color: #bbb;
    font-weight: bold;
    position: relative;
    transition: color 0.2s ease-in-out;
}

.header-menu a:hover,
.header-menu a.active {
    color: #228be6;
}

.header-menu a.active::after {
    position: absolute;
    content: " ";
    height: 0.1vw;
    width: 100%;
    background: #228be6;
    bottom: -0.5vw;
    left: 50%;
    transform: translate(-50%, 0);
}

.header-content {
    position: absolute;
    top: 55%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#header-overlay {
    position: absolute;
    background: rgb(51,65,95, 0.4);
    height: 100%;
    width: 100%;
}

.call-to-action {
    padding: 1vw 1.5vw;
    font-size: 1.1vw;
    letter-spacing: 0.2vw;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif;
    text-decoration: none;
}

.header-content #titles {
    margin-bottom: 4vw;
}

.header-content #main-title {
    font-size: 4vw;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: #fff;
    text-shadow: 1px 1px #000;
    letter-spacing: 0.15vw;
    font-family: 'Roboto Condensed', sans-serif;
}

.header-content #sub-title {
    font-size: 1.5vw;
    color: #f9f9f9;
    text-shadow: 1px 1px #000;
    font-style: italic;
    
}

.bg-blue {
    background: #228be6;
}

.bg-blue:hover {
    background: #196eb7;
}

footer {
    padding-top: 10vh;
    padding-bottom: calc(5vh + 50px);
    background: linear-gradient(45deg, #33416A 1%, #2f76af 64%, #1e88e5 97%);
}

footer * {
    margin: 0;
    color: rgba(196, 227, 255, 0.6);
}

footer .footer-content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
}

footer .footer-content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

footer .footer-content h3 {
    font-weight: 400;
    font-size: 1.4vw;
    letter-spacing: 0.15vw;
    font-family: 'Roboto Condensed', sans-serif;
    color: #f9f9f9;
}

footer .footer-content p {
    font-weight: 600;
    font-size: 1vw;
}

footer .bottom-footer {
    background: #212121;
    height: 2.5vw;
    font-size: 0.7vw;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

footer .bottom-footer span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #a8a8a8;
}

footer .bottom-footer a {
    text-decoration: none;
}

footer .contact-info p {
    margin: 0.6vw 0;
}

.footer-block {
    margin-right: 4vw;
}

footer nav a {
    display: block;
    text-decoration: none;
    margin: 0.5vw 0;
    font-size: 1vw;
    font-weight: bold;
    transition: color 0.2s ease-in-out;
}

footer nav a:hover {
    color: #fff;
}

footer #social-media-links {
    font-size: 1.4vw;
    margin-top: 1.5vw;
}

footer #social-media-links a {
    display: inline-block;
    position: relative;
    margin-right: 2vw;
    cursor: pointer;
}

footer #social-media-links a i {
    color: #fff;
    transition: color 200ms ease-in-out;
}

footer #social-media-links a:hover i {
    color: rgba(196, 227, 255, 0.6);
}

.m-bottom {
    margin-bottom: 1vw;
}

@media only screen and (max-width: 480px) {

    header {
        height: 80px;
    }

    main {
        min-height: calc(100vh - 80px);
    }

    .container.padding {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .header-menu a {
        font-size: 0.8em;
        margin:  0 8px;
    }

    header .container div:first-of-type {
        display: none;
    }
    
    header .container div:last-of-type {
        margin: 0 auto;
    }

    #main-header {
        background-position: center;
    }

    .header-content {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .header-content #titles {
        margin-bottom: 4vw;
    }
    
    .header-content #main-title {
        font-size: 2em;
        line-height: 1.5em;
        letter-spacing: 2px;
    }
    
    .header-content #sub-title {
        font-size: 1em;    
        line-height: 1.5em;
        margin-bottom: 50px;    
    }

        
    .call-to-action {
        padding: 9px 15px;
        font-size: 0.9em;
    }

    footer .bottom-footer {
        min-height: 40px;
        padding: 10px 0;
        font-size: 0.8em;
    }

    footer .footer-content h3 {
        font-size: 1.2em;
        letter-spacing: 1.5px;
        margin-bottom: 10px;
    }

    footer .footer-block {
        width: 80%;
        margin-bottom: 20px;
    }
    
    footer nav {
        width: 60%;
    }

    footer nav a {
        font-size: 0.8em;
        display: block;
        margin: 15px 0;
    }

    footer .footer-content p {
        font-size: 0.7em;
        margin: 10px 0;
    }
    
    
    footer #social-media-links {
        font-size: 1.2em;
    }
}