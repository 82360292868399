form {
    width: 30vw;
    margin: 3vw 0;
}

.form-group {
    margin-bottom: 0.8vw;
}

label {
    display: inline-block;
    margin-bottom: 0.8vw;
    font-size: 1vw;
    font-weight: bold;
}

span.error-message {
    color: #d82d2d;
    right: 0;
    margin-bottom: 0.8vw;
    font-size: 1vw;
    font-weight: bold;
    float: right;
}

#form-error.error-message {
    float: none !important;
}

.text-gray {
    color: #595959;
}

.form-control {
    padding: 0.9vw 1.1vw;
    border: 1px solid #ccc;
    font-size: 1vw;
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    outline: none;
}

textarea.form-control {
    resize: vertical;
    font-family: 'Lato', sans-serif;
}


.form-buttons {
    display: flex;
    justify-content: flex-end;
}

#submit-btn {
    padding: 0.7vw 1.5vw;
    font-size: 0.95vw;
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 0.5vw;
}

.spaced-flex {
    display: flex;
    justify-content: space-between;
}

.contact-header {
    font-size: 2vw;
    line-height: 1.5em;
    font-weight: 100;
    letter-spacing: 1px;
    margin-top: 5vw;
}

#contact-information {
    box-sizing: border-box;
    width: 55%;
}

.contact-instructions {
    color: #888;
    line-height: 1.5em;
}

.contact-buttons {
    margin-top: 2vw;
}

.contact-button {
    text-decoration: none;
    padding: 0.9vw 1.5vw;
    border-radius: 0.5vw;
    color: #fff;
    letter-spacing: 2px;
    word-spacing: 5px;
    font-size: 1vw;
}

.contact-button:first-of-type {
    margin-right: 4vw;
}

@media only screen and (max-width: 480px) {

    form {
        width: 100% !important;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .spaced-flex {
        justify-content: flex-start !important;
        flex-direction: column-reverse;
        flex-wrap: wrap !important;
    }

    .form-control {
        padding: 9px 14px !important;
        font-size: 1em !important;
    }

    label,
    span.error-message {
        margin-bottom: 10px;
        font-size: 0.9em;
    }

    .contact-header {
        font-size: 1.5em;
        margin-top: 30px;
    }
    
    #contact-information {
        box-sizing: border-box;
        width: 100%;
    }

    .contact-button {
        padding: 12px 15px;
        margin: 10px 0;
        border-radius: 5px;
        letter-spacing: 2px;
        word-spacing: 5px;
        font-size: 1em;
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
    }

    #submit-btn {
        padding: 12px;
        width: 100%;
        font-size: 1em;
        border-radius: 5px;
    }

}